<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="editProfileForm">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-card
          class="p-2"
        >
          <b-card-text class="d-flex justify-content-center pb-3">
            Revise los siguientes datos y actualice los que sean necesarios. Luego de click en "Guardar" para poder continuar utilizando los servicios de EFIDE factoring digital.
          </b-card-text>
          <b-row
            v-if="user.clientType == 0"
            class="d-flex justify-content-center"
          >
            <!-- phone -->
            <b-col
              lg="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label-cols-sm="3"
                content-cols-sm="9"
                label="Celular*"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="celular"
                  rules="max:9|required"
                  vid="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    :formatter="formatterNumber"
                    name="phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- address -->
            <b-col
              lg="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label-cols-sm="3"
                content-cols-sm="9"
                label="Dirección*"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dirección"
                  rules="max:150|required"
                  vid="address"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    name="address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="7"
              md="9"
              sm="12"
            >
              <!-- country -->
              <b-form-group
                label-cols-sm="3"
                content-cols-sm="9"
                label="País*"
                label-for="country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="país"
                  rules="required"
                  vid="country"
                >
                  <b-form-input
                    id="country"
                    v-model="country"
                    :state="errors.length > 0 ? false:null"
                    name="country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="7"
              md="9"
              sm="12"
            >
              <!-- reference -->
              <b-form-group
                label-cols-sm="3"
                content-cols-sm="9"
                label="Referencia*"
                label-for="reference"
              >
                <validation-provider
                  #default="{ errors }"
                  name="referencia"
                  rules="required"
                  vid="reference"
                >
                  <b-form-input
                    id="reference"
                    v-model="reference"
                    :state="errors.length > 0 ? false:null"
                    name="reference"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="7"
              md="9"
              sm="12"
            >
              <!-- ubigee -->
              <b-form-group
                label-cols-sm="3"
                content-cols-sm="9"
                label="Ubigeo*"
                label-for="ubigee"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ubigeo"
                  rules="min:6|max:6|required"
                  vid="ubigee"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                        variant="outline-primary"
                        class="btn-icon"
                      >
                        <feather-icon
                          size="15"
                          icon="InfoIcon"
                        />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      id="ubigee"
                      v-model="ubigee"
                      :formatter="formatterNumber"
                      :state="errors.length > 0 ? false:null"
                      name="ubigee"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row
                class="justify-content-sm-end my-2"
              >
                <b-col
                  sm="6"
                  lg="8"
                  class="d-flex justify-content-sm-end justify-content-center"
                >
                  <b-badge
                    href="/ubigeo"
                    variant="light-primary"
                    target="_blank"
                    style="white-space: pre-wrap;"
                  > Puede encontrar su UBIGEO hacienco click AQUÍ </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            v-if="user.clientType == 1"
            class="d-flex justify-content-center"
          >
            <b-col
              md="10"
              sm="12"
              class="mb-2"
            >
              <p
                class="border-bottom"
              >
                Datos de la persona de contacto
              </p>
            </b-col>
            <!-- fullName -->
            <b-col
              g="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label="Nombre completo*"
                label-for="fullName"
                label-cols-sm="3"
                content-cols-sm="9"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nombre completo"
                  rules="max:100|required"
                  vid="fullName"
                >
                  <b-form-input
                    id="fullName"
                    v-model="fullName"
                    :state="errors.length > 0 ? false:null"
                    name="fullName"
                    placeholder="Juan Alvaro Perez Gonzales"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- position -->
            <b-col
              g="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label="Cargo*"
                label-for="position"
                label-cols-sm="3"
                content-cols-sm="9"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cargo*"
                  rules="max:20|required"
                  vid="position"
                >
                  <b-form-input
                    id="position"
                    v-model="position"
                    :state="errors.length > 0 ? false:null"
                    name="position"
                    placeholder="Gerente"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- telephone -->
            <b-col
              g="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label="Número de teléfono*"
                label-for="telephone"
                label-cols-sm="3"
                content-cols-sm="9"
              >
                <validation-provider
                  #default="{ errors }"
                  name="número de Teléfono"
                  rules="min:9|max:15|required"
                  vid="telephone"
                >
                  <b-form-input
                    id="telephone"
                    v-model="telephone"
                    :state="errors.length > 0 ? false:null"
                    name="telephone"
                    :formatter="formatterTel"
                    placeholder="999 999 999"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- email -->
            <b-col
              g="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label="Correo electónico*"
                label-for="email"
                label-cols-sm="3"
                content-cols-sm="9"
              >
                <validation-provider
                  #default="{ errors }"
                  name="correo electónico"
                  rules="email|required"
                  vid="email"
                >
                  <b-form-input
                    id="email"
                    v-model="contactEmail"
                    :state="errors.length > 0 ? false:null"
                    name="email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="10"
              sm="12"
              class="mb-2"
            >
              <p
                class="border-bottom"
              >
                Datos de la empresa
              </p>
            </b-col>
            <!-- address -->
            <b-col
              g="7"
              md="9"
              sm="12"
            >
              <b-form-group
                label="Dirección*"
                label-for="address"
                label-cols-sm="3"
                content-cols-sm="9"
              >
                <validation-provider
                  #default="{ errors }"
                  name="dirección"
                  rules="max:150|required"
                  vid="address"
                >
                  <b-form-input
                    id="address"
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    name="address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- UBIGEO -->
            <b-col
              g="7"
              md="9"
              sm="12"
            >
              <!-- ubigee -->
              <b-form-group
                label="Ubigeo*"
                label-for="ubigee"
                label-cols-sm="3"
                content-cols-sm="9"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ubigeo"
                  rules="min:6|max:6|required"
                  vid="ubigee"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="'Ubigeo son las siglas oficiales para Código de Ubicación Geográfica que usan el INEI, el RENIEC y la ONPE para codificar las circunscripciones'"
                        variant="outline-primary"
                        class="btn-icon"
                      >
                        <feather-icon
                          size="15"
                          icon="InfoIcon"
                        />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      id="ubigee"
                      v-model="ubigee"
                      :formatter="formatterNumber"
                      :state="errors.length > 0 ? false:null"
                      name="ubigee"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row
                class="justify-content-center m-2"
              >
                <b-badge
                  href="/ubigeo"
                  variant="light-primary"
                  target="_blank"
                  style="white-space: pre-wrap;"
                > Puede encontrar su UBIGEO hacienco click AQUÍ </b-badge>
              </b-row>
            </b-col>
          </b-row>
          <input
            id="email"
            v-model="email"
            type="hidden"
            name="email"
          >
        </b-card>
        <b-row
          class="justify-content-center"
        >
          <b-col
            sm="12"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BFormGroup, BFormInput, BButton, BRow, BCol, BBadge, VBTooltip, BInputGroupPrepend, BInputGroup, BCardText,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BBadge,
    BInputGroupPrepend,
    BInputGroup,
    ValidationObserver,
    BCardText,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    // Max date for year validation
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setFullYear(maxDate.getFullYear() - 18)
    return {
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      user: JSON.parse(localStorage.getItem('userData')),
      phone: '',
      address: '',
      country: '',
      reference: '',
      ubigee: '',
      fullName: '',
      position: '',
      telephone: '',
      contactEmail: '',
      // Validation rules
      required,
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    if (dataU.clientType === 0) {
      this.phone = dataU.contactLocationData && dataU.contactLocationData.phone ? dataU.contactLocationData.phone : ''
      this.address = dataU.contactLocationData && dataU.contactLocationData.address ? dataU.contactLocationData.address : ''
      this.country = dataU.contactLocationData && dataU.contactLocationData.country ? dataU.contactLocationData.country : ''
      this.reference = dataU.contactLocationData && dataU.contactLocationData.reference ? dataU.contactLocationData.reference : ''
      this.ubigee = dataU.contactLocationData && dataU.contactLocationData.ubigee ? dataU.contactLocationData.ubigee : ''
    } else if (dataU.clientType === 1) {
      this.fullName = dataU.contactPerson && dataU.contactPerson.fullName ? dataU.contactPerson.fullName : ''
      this.position = dataU.contactPerson && dataU.contactPerson.position ? dataU.contactPerson.position : ''
      this.telephone = dataU.contactPerson && dataU.contactPerson.telephone ? dataU.contactPerson.telephone : ''
      this.contactEmail = dataU.contactPerson && dataU.contactPerson.email ? dataU.contactPerson.email : ''
      this.address = dataU.locationData && dataU.locationData.address ? dataU.locationData.address : ''
      this.ubigee = dataU.locationData && dataU.locationData.ubigee ? dataU.locationData.ubigee : ''
    }
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.editProfileForm.validate().then(success => {
        if (success) {
          useJwt.postEditProfileClient({
            contactLocationData: {
              phone: this.phone,
              address: this.address,
              country: this.country,
              reference: this.reference,
              ubigee: this.ubigee,
            },
            contactPerson: {
              fullName: this.fullName,
              position: this.position,
              telephone: this.telephone,
              email: this.contactEmail,
            },
            locationData: {
              address: this.address,
              ubigee: this.ubigee,
            },
            clientType: this.user.clientType,
            email: this.email,
            ruc: this.ruc,
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              this.$router.replace('/cliente/panel')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                    },
                  },
                  {
                    timeout: 8000,
                  })
                })
            })
            .catch(error => {
              this.$refs.editProfileForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
